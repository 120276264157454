body {
  font-family: Lato;
}

#toast-container {
  margin-top: 1rem;
}

#toast-container.toast-top-full-width>.toast {
  max-width: 280px;
  float: right;
  margin-right: 10px;
}

#toast-container>.toast-success {
  background-color: #28a745;
  opacity:1;
  box-shadow:0px 0px 10px rgba(0,0,0,0.2);
}

#toast-container>.toast-success:hover {
  background-color: #28a745;
  opacity:1;
  box-shadow:0px 0px 10px rgba(0,0,0,0.4);
}




html,
body {
	height: 100%;
}

body {
	font-family: Jost, sans-serif;
	margin: 0;
	padding: 0;
	font-size: 14px;
	width: 100%;
	overflow-x: hidden;
	font-weight: 400;
	background: #f2f3f8;
}

ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

a,
a:hover {
	text-decoration: none;
	color: #676767;
}

:focus {
	outline: none;
}

button:focus {
	outline: none;
}


::-webkit-input-placeholder { 
	color: #495057;
}

::-moz-placeholder {
	color: #495057;
}

:-ms-input-placeholder { 
	color: #495057;
}

:-moz-placeholder { 
	color: #495057;
}

/*============== sidebar / header css =============*/
.wrapper-class {
	width: 100%;
	top: 0;
	position: relative;
	height: auto;
	min-height: 100%;
}

.sidebar-main-div {
	background-color: #fff;
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 1000;
	width: 230px;
	height: 100%;
	-webkit-transition-property: top,bottom,width;
	-moz-transition-property: top,bottom,width;
	-ms-transition-property: top,bottom,width;
	-o-transition-property: top,bottom,width;
	transition-property: top,bottom,width;
	-webkit-transition-duration: .2s,.2s,.35s;
	-moz-transition-duration: .2s,.2s,.35s;
	-ms-transition-duration: .2s,.2s,.35s;
	-o-transition-duration: .2s,.2s,.35s;
	transition-duration: .2s,.2s,.35s;
	-webkit-transition-timing-function: linear,linear,ease;
	-moz-transition-timing-function: linear,linear,ease;
	-ms-transition-timing-function: linear,linear,ease;
	-o-transition-timing-function: linear,linear,ease;
	transition-timing-function: linear,linear,ease;
	-webkit-overflow-scrolling: touch;
	box-shadow: 2px 3px 4px rgba(222, 226, 230,0.6);
	-webkit-box-shadow: 2px 3px 4px rgba(222, 226, 230,0.6);
	-moz-box-shadow: 2px 3px 4px rgba(222, 226, 230,0.6);
}

.content-wrapper-main {
	margin-left: 230px;
	padding-top: 80px;
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 50px;
	overflow-x: hidden;
	-webkit-transition-property: top,bottom,width;
	-moz-transition-property: top,bottom,width;
	-ms-transition-property: top,bottom,width;
	-o-transition-property: top,bottom,width;
	transition-property: top,bottom,width;
	-webkit-transition-duration: .2s,.2s,.35s;
	-moz-transition-duration: .2s,.2s,.35s;
	-ms-transition-duration: .2s,.2s,.35s;
	-o-transition-duration: .2s,.2s,.35s;
	transition-duration: .2s,.2s,.35s;
	-webkit-transition-timing-function: linear,linear,ease;
	-moz-transition-timing-function: linear,linear,ease;
	-ms-transition-timing-function: linear,linear,ease;
	-o-transition-timing-function: linear,linear,ease;
	transition-timing-function: linear,linear,ease;
	-webkit-overflow-scrolling: touch;
}

.wrapper-class.toggled-cust .content-wrapper-main {
	margin-left: 60px;
}

.wrapper-class.toggled-cust .sidebar-main-div {
	width: 60px;
}

.wrapper-class.toggled-cust .sidebar-inner-menu li a span{
	opacity: 0;
	-webkit-transform: translate3d(-25px,0,0);
	-moz-transform: translate3d(-25px,0,0);
	-ms-transform: translate3d(-25px,0,0);
	-o-transform: translate3d(-25px,0,0);
	transform: translate3d(-25px,0,0);
}

.wrapper-class.toggled-cust .brand-logo-img{
	width: 60px;
}

.sidebar-inner-menu li span{
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
	-o-transform: translateZ(0);
	transform: translateZ(0);
	-moz-opacity: 1;
	opacity: 1;
}

.wrapper-class.toggled-cust .brand-logo-img a span{
	visibility: hidden;
	opacity: 0;
}

.wrapper-class.toggled-cust .sidebar-inner-menu li{
	margin: 5px;
}

.brand-logo-img {
	height: 60px;
	line-height: 60px;
	margin-bottom: 0;
	position: fixed;
	background: #1e2b3a;
	z-index: 99;
	-webkit-transition-property: top,bottom,width;
	-moz-transition-property: top,bottom,width;
	-ms-transition-property: top,bottom,width;
	-o-transition-property: top,bottom,width;
	transition-property: top,bottom,width;
	-webkit-transition-duration: .2s,.2s,.35s;
	-moz-transition-duration: .2s,.2s,.35s;
	-ms-transition-duration: .2s,.2s,.35s;
	-o-transition-duration: .2s,.2s,.35s;
	transition-duration: .2s,.2s,.35s;
	-webkit-transition-timing-function: linear,linear,ease;
	-moz-transition-timing-function: linear,linear,ease;
	-ms-transition-timing-function: linear,linear,ease;
	-o-transition-timing-function: linear,linear,ease;
	transition-timing-function: linear,linear,ease;
	-webkit-overflow-scrolling: touch;
	width: 230px;
}

.brand-logo-img img {
	width: 33px;
}

.brand-logo-img a span{
	margin-left: 15px;
	margin-top: 2px;
	color: #fff;
	font-weight: 600;
	-webkit-transition-property: top,bottom,width;
	-moz-transition-property: top,bottom,width;
	-ms-transition-property: top,bottom,width;
	-o-transition-property: top,bottom,width;
	transition-property: top,bottom,width;
	-webkit-transition-duration: .2s,.2s,.35s;
	-moz-transition-duration: .2s,.2s,.35s;
	-ms-transition-duration: .2s,.2s,.35s;
	-o-transition-duration: .2s,.2s,.35s;
	transition-duration: .2s,.2s,.35s;
	-webkit-transition-timing-function: linear,linear,ease;
	-moz-transition-timing-function: linear,linear,ease;
	-ms-transition-timing-function: linear,linear,ease;
	-o-transition-timing-function: linear,linear,ease;
	transition-timing-function: linear,linear,ease;
	-webkit-overflow-scrolling: touch;
	font-size: 16px;
}

.sidebar-wrapper-inner {
	position: relative;
	z-index: 1;
	padding-top: 70px;
	height: 100%;
	overflow-y: auto;
}

.sidebar-inner-menu li {
	position: relative;
	margin: 5px 15px;
}

.active-menu a{
	background: #e0f4ea;
}

.sidebar-inner-menu li a {
	padding: 15px;
	display: -ms-flexbox;
	display: flex;
	color: #000D24;
	font-size: 16px;
	text-transform: capitalize;
	-ms-flex-align: center;
	align-items: center;
	font-weight: 600;
	-webkit-transition-property: top,bottom,width;
	-moz-transition-property: top,bottom,width;
	-ms-transition-property: top,bottom,width;
	-o-transition-property: top,bottom,width;
	transition-property: top,bottom,width;
	-webkit-transition-duration: .2s,.2s,.35s;
	-moz-transition-duration: .2s,.2s,.35s;
	-ms-transition-duration: .2s,.2s,.35s;
	-o-transition-duration: .2s,.2s,.35s;
	transition-duration: .2s,.2s,.35s;
	-webkit-transition-timing-function: linear,linear,ease;
	-moz-transition-timing-function: linear,linear,ease;
	-ms-transition-timing-function: linear,linear,ease;
	-o-transition-timing-function: linear,linear,ease;
	transition-timing-function: linear,linear,ease;
	-webkit-overflow-scrolling: touch;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	white-space: nowrap;
}

.sidebar-inner-menu li a img {
	width: 20px;
	height: 20px;
	margin: -3px 15px 0 0;
}

.header-nav .navbar {
	padding: 7px 15px;
	z-index: 999;
	height: 60px;
	background-color: #fff;
	box-shadow: 0 3px 3px rgba(222, 226, 230,0.6);
	-webkit-box-shadow: 0 3px 3px rgba(222, 226, 230,0.6);
	-moz-box-shadow: 0 3px 3px rgba(222, 226, 230,0.6);
}

.toggle-icon i {
	font-size: 24px;
	font-weight: 600;
	top: 0;
	position: relative;
	color: #1e2b3a;
	cursor: pointer;
	-webkit-transition: all 0s ease;
	-moz-transition: all 0s ease;
	-o-transition: all 0s ease;
	-ms-transition: all 0s ease;
	transition: all 0s ease;
	visibility: visible;
	margin-left: 230px;
}

.wrapper-class.toggled-cust .menu-icon {
	margin-left: 50px;
}

.header-usr {
	position: relative;
	padding-right: 0;
	padding-left: 25px;
	text-align: right;
	padding-top: 0;
	cursor: pointer;
}

.header-usr-inner img {
	width: 45px;
	position: absolute;
	right: 0;
	top: 0;
	margin: 0 0 0 0;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
}

.header-usr-inner h6 {
	font-weight: 500;
	font-size: 16px;
	color: #1e2b3a;
	margin: 0;
	text-transform: capitalize;
}

.header-usr-inner span {
	color: #1e2b3a;
	font-size: 15px;
}

.profile-inr-main {
	position: absolute;
	z-index: -1;
	background: #fff;
	width: 170px;
	right: 0;
	-webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0);
	-moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0);
	box-shadow: 0 0 4px rgba(0, 0, 0, 0);
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	-webkit-transform: translate3d(0, -20px, 0);
	-moz-transform: translate3d(0, -20px, 0);
	-ms-transform: translate3d(0, -20px, 0);
	-o-transform: translate3d(0, -20px, 0);
	transform: translate3d(0, -20px, 0);
	-moz-opacity: 0;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all .15s linear;
	-moz-transition: all .15s linear;
	-ms-transition: all .15s linear;
	-o-transition: all .15s linear;
	transition: all .15s linear;
}

.profile-inr-main ul:before {
	content: "";
	width: 25px;
	height: 11px;
	position: absolute;
	top: -11px;
	right: 3px;
}

.profile-inr-main ul li {
	width: 100%;
	text-align: left;
	margin-left: 0;
}

.profile-inr-main ul li a {
	padding: 10px 10px;
	color: #1e2b3a;
	font-weight: 500;
	display: -ms-flexbox;
	display: flex;
	text-transform: capitalize;
	-ms-flex-align: center;
	align-items: center;
	font-size: 13px;
	white-space: nowrap;
	border-bottom: 1px solid #f0f0f0;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	-ms-transition: all 0.15s;
	-o-transition: all 0.15s;
	transition: all 0.15s;
}

.profile-inr-main ul li a:hover {
	background: rgb(224, 244, 234);
}

.profile-inr-main i {
	margin: 0 10px 0 0;
	font-size: 18px;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	-ms-transition: all 0.15s;
	-o-transition: all 0.15s;
	transition: all 0.15s;
	color: #252D49;
}

.header-usr.active .profile-inr-main {
	-webkit-transform: translate3d(0, 30px, 0);
	-moz-transform: translate3d(0, 30px, 0);
	-ms-transform: translate3d(0, 30px, 0);
	-o-transform: translate3d(0, 30px, 0);
	transform: translate3d(0, 30px, 0);
	-moz-opacity: 1;
	opacity: 1;
	z-index: 99;
	visibility: visible;
}

.dash-main-hdg h2{
	color: #1e2b3a;
	font-weight: 600;
	margin: 0;
	font-size: 24px;
	text-transform: capitalize;
}

.dash-main-hdg {
	padding-bottom: 20px;
}

.chart-dash-sec-btn ul li button{
	border: 0;
	background: #abb9f2;
	padding: 10px 15px;
	color: #fff !important;
	text-transform: uppercase;
	font-weight: 500;
	margin-right: 5px;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	box-shadow: none !important;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
}

.chart-dash-sec-btn ul li button.active{
	background: #5d72e4;
}

.chart-dash-sec-date label{
	font-weight: 600;
	font-size: 16px;
	display: block;
	margin-bottom: 5px;
	color: rgb(116, 120, 141);
}

.chart-dash-sec-date input{
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	box-shadow: 0 0 3px rgba(0,0,0,0.3);
	-moz-box-shadow: 0 0 3px rgba(0,0,0,0.3);
	-webkit-box-shadow: 0 0 3px rgba(0,0,0,0.3);
	color: #000;
	border: 0;
	padding: 10px 12px;
	height: auto;
}

.chart-dash-sec-date input:focus{
	box-shadow: 0 0 3px rgba(0,0,0,0.5);
	-moz-box-shadow: 0 0 3px rgba(0,0,0,0.5);
	-webkit-box-shadow: 0 0 3px rgba(0,0,0,0.5);
}

.chart-dash-sec {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}

.chart-dash-sec-buttons button{
	border: 0;
	background: #5e72e4;
	padding: 10px 10px;
	color: #fff !important;
	text-transform: uppercase;
	font-weight: 500;
	margin-right: 5px;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	white-space: nowrap;
	box-shadow: none !important;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
}

.chart-dash-sec-date ul li{
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-ms-flex-positive: 1;
	flex-grow: 1;
	min-width: 0;
	max-width: 100%;
}

.left-side-dropdown h3{
	background: rgb(94, 114, 228);
	margin: 0;
	color: #fff;
	text-align: center;
	padding: 11px 0 12px 0;
	font-size: 24px;
}

.left-side-dropdown-inner input{
	border-radius: 0;
	border: 1px solid #000 !important;
	box-shadow: none !important;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
}

.left-side-dropdown-inner-scrll{
	background-color: rgb(255, 255, 255);
	box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 3px;
	overflow: hidden scroll;
	z-index: 99;
	max-height: 200px;
	width: 100%;
}

.left-side-dropdown-inner-scrll ul li{
	display: block;
}

.left-side-dropdown-inner-scrll ul li p{
	margin: 10px 0 10px 0;
	color: #1e2b3a;
	font-weight: 500;
	font-size: 15px;
	cursor: pointer;
	text-transform: uppercase;
	padding: 0 10px;
}

.cust-wdth{
	max-width: 220px;
}

.tkr-bg{
	background: rgb(87, 215, 227) !important;
}

.left-side-dropdown {
	padding-bottom: 30px;
}

.chart-dash-main{
	padding-top: 45px;
}

.chart-dash-main #chart {
	max-width: 100%;
	margin: auto;
}

.chart-dash-sec-date {
	margin: 0 15px;
	margin-top: -25px;
}

.chart-dash-main .apexcharts-canvas{
	width: 100% !important;
}

.dash-pdf-main-bx{
	background: rgba(0,0,0,.03);
	box-shadow: 0 0 4px rgba(63,63,68,0.3);
	-webkit-box-shadow: 0 0 4px rgba(63,63,68,0.3);
	-moz-box-shadow: 0 0 4px rgba(63,63,68,0.3);
	padding: 25px;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	position: relative;
	overflow: hidden;
	margin-top: 15px;
}

.dash-pdf-main-bx h4{
	margin: 0;
	font-size: 16px;
}

.sidebar-inner-menu li{
	margin: 0 10px;
}

.dash-pdf-main-bx .input-group{
	padding-top: 20px;
}

.dash-pdf-main-bx label{
	margin: 0;
	background: #fff;
	padding: 10px 15px;
	border-top-left-radius: 3px;
	-webkit-border-top-left-radius: 3px;
	-moz-border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
	-webkit-border-bottom-left-radius: 3px;
	-moz-border-bottom-left-radius: 3px;
	font-weight: 400;
	opacity: 1;
}

.dash-pdf-main-bx .btn{
	border: 0;
	text-transform: capitalize;
	font-weight: 500;
	font-size: 16px;
	padding: 6px 20px;
	border-radius: 3px;
	border-top-left-radius: 0;
	-webkit-border-top-left-radius: 0;
	-moz-border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	-webkit-border-bottom-left-radius: 0;
	-moz-border-bottom-left-radius: 0;
	box-shadow: none !important;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
}

.dash-pdf-main-bx h5{
	margin: 0;
	font-size: 16px;
}

.dash-pdf-main-bx-tbl{
	background: rgba(0,0,0,.03);
	margin-top: 30px;
	padding: 20px 0;
}

.dash-pdf-tbl-data:last-child {
	border-bottom: 0;
	padding: 0;
	margin-top: 15px;
}

.dash-pdf-main-bx-tbl p{
	margin-bottom: 0;
	color: #000;
	font-weight: 600;
	font-size: 16px;
	text-align: center;
}

.dash-pdf-tbl-data p{
	margin: 0;
	text-align: center;
	font-size: 15px;
	margin-left: 10px;
	font-weight: 500;
}

.dash-pdf-tbl-data img{
	width: 35px;
}

.dash-pdf-tbl-data {
	border-bottom: 1px solid rgb(154, 154, 154);
	padding: 15px 0;
	margin-bottom: 0;
}

.cls-wdt p{
	font-size: 14px;
}

.cls-wdt img{
	width: 15px;
	margin-left: 10px;
}

.left-side-login {
	padding: 50px 0 50px 50px;
}

.right-rounded{
	background: rgb(37, 71, 78);
	width: 100px;
	height: 100px;
	border-radius: 50%;
	color: #fff;
	font-weight: 600;
	font-size: 18px;
	text-transform: capitalize;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-align: center;
	margin: 0 auto;
}

.right-login-detls h4{
	padding-top: 20px;
	margin: 0;
	font-size: 26px;
}

.right-login-detls label{
	font-weight: 600;
	font-size: 16px;
	display: block;
	margin-bottom: 5px;
	color: #000;
}

.right-login-detls input {
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	box-shadow: 0 0 3px rgba(0,0,0,0.3);
	-moz-box-shadow: 0 0 3px rgba(0,0,0,0.3);
	-webkit-box-shadow: 0 0 3px rgba(0,0,0,0.3);
	border: 0;
	color: #000;
	font-size: 16px;
	padding: 10px 12px;
	height: auto;
	width: 100%;
}

.right-login-detls input:focus {
	box-shadow: 0 0 3px rgba(0,0,0,0.5);
	-moz-box-shadow: 0 0 3px rgba(0,0,0,0.5);
	-webkit-box-shadow: 0 0 3px rgba(0,0,0,0.5);
}

.right-login-detls form{
	max-width: 500px;
	margin: 0 auto;
	padding-top: 50px;
}

.right-login-detls button{
	border: 0;
	background: rgb(3, 61, 73);
	color: #fff !important;
	font-size: 16px;
	text-transform: capitalize;
	font-weight: 500;
	border-radius: 28px;
	padding: 15px 55px;
	display: inline-block;
	text-align: center;
	margin: 0 auto;
	box-shadow: none !important;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
}



















/*========================= media query ===================*/
@media (max-width: 1199px){
	.left-side-dropdown{
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		min-width: 0;
		max-width: 100%;
		padding-left: 15px;
	}

	.left-side-dropdown:first-child{
		padding-left: 0;
	}

	.lft-sdr-div{
		display: -ms-flexbox;
		display: flex;
	}

	.cust-wdth {
		max-width: 100%;
	}
}

@media (max-width: 991px){
	.toggled-cust .overlay {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1000;
		background: rgba(0, 0, 0, 0.9);
		opacity: 0.4;
		z-index: 999;
		display: block;
	}

	.toggle-icon i{
		margin-left: 0;
	}

	.sidebar-main-div{
		position: fixed;
		top: 0px;
		left: -250px;
		z-index: 1000;
		width: 250px;
		height: 100%;
		box-shadow: none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		transition: all 0.3s;
		-o-transition: all 0.3s;
		-moz-transition: all 0.3s;
		-webkit-transition: all 0.3s;
		-ms-transition: all 0.3s;
	}

	.wrapper-class.toggled-cust .sidebar-main-div{
		position: fixed;
		top: 0px;
		left: 0px;
	}

	.wrapper-class.toggled-cust .sidebar-main-div {
		width: 250px;
	}

	.wrapper-class.toggled-cust .brand-logo-img {
		width: 250px;
	}

	.wrapper-class.toggled-cust .brand-logo-img a span {
		visibility: visible;
		opacity: 1;
	}

	.wrapper-class.toggled-cust .sidebar-inner-menu li a span {
		visibility: visible;
		opacity: 1;
		display: block;
		margin-left: 25px;
	}

	.header-usr{
		padding-right: 0;
	}

	.header-usr-inner i {
		font-size: 30px;
	}

	.profile-inr-main{
		right: 0;
	}

	.content-wrapper-main{
		margin-left: 0px;
		padding-bottom: 20px;
	}

	.wrapper-class.toggled-cust .content-wrapper-main {
		margin-left: 0;
	}

	.ltr-pd{
		padding-right: 15px !important;
		padding-left: 15px !important;
	}

	.left-side-login {
		padding: 0;
		text-align: center;
		padding-top: 20px;
	}

	.right-login-detls {
		padding-top: 30px;
		padding-bottom: 50px;
	}
}

@media(max-width: 767px){
	.chart-dash-sec{
		display: block;
	}

	.chart-dash-sec-btn{
		position: relative;
		overflow: hidden;	
	}

	.chart-dash-sec-date{
		margin: 20px 0;
		margin-top: 20px;
	}
}



@media(max-width: 575px){
	.lft-sdr-div{
		display: block;
	}

	.left-side-dropdown{
		padding-left: 0;
	}

	.dash-pdf-main-bx label{
		width: 100%;
	}

	.dash-pdf-main-bx .btn{
		margin-top: 15px;
	}

	.dash-pdf-main-bx{
		padding: 15px;
	}

	.right-login-detls form{
		max-width: 100%;
	}
}
